<template>
  <a-modal :maskClosable="false" title="字典详情" style="top: 8px;" :width="1000" v-model="visible" :footer="null">
    <a-button type="primary" @click="toHanlder('add')">新增字典值</a-button>
    <a-form-model v-if="handle=='add'||handle=='edit'" ref="form" :model="form" :rules="rules" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
      <a-form-model-item label="字典编号" prop="itemId">
        <a-input disabled v-model="form.dictCode"  placeholder="字典编号" />
      </a-form-model-item>
      <a-form-model-item label="字典值名称" prop="title">
        <a-input v-model.trim="form.title"  placeholder="字典值名称" />
      </a-form-model-item>
      <!-- <a-form-model-item label="字典值编号" prop="itemCode">
        <a-input v-model.trim="form.dictCode"  placeholder="字典值编号" />
      </a-form-model-item> -->
      <!-- <a-form-model-item label="是否默认" prop="flagEnable">
        <a-radio-group v-model="form.flagEnable" :default-value="0">
          <a-radio :value="0">不默认</a-radio>
          <a-radio :value="1">默认</a-radio>
        </a-radio-group>
      </a-form-model-item> -->
      <a-form-model-item label="字典状态" prop="flagEnable">
        <a-radio-group v-model="form.flagEnable">
          <a-radio :value="true">启用</a-radio>
          <a-radio :value="false">停用</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="字典值描述" prop="description">
        <a-textarea v-model.trim="form.description" :max-length="200" placeholder="字典值描述"   />
      </a-form-model-item>
      <a-form-model-item label="字典值排序" prop="sortCode">
        <a-input v-model.trim="form.sortCode"  placeholder="字典值排序" />
      </a-form-model-item>
      <a-form-model-item :label-col="{span:0}" :wrapper-col="{span:21,offset:3}">
        <a-button type="primary" @click="toSubmit()">确认</a-button>
        <a-button type="default" @click="()=>{handle=''}">取消</a-button>
      </a-form-model-item>
    </a-form-model>

    <a-table :rowKey="record=>record.id" :columns="columns" :data-source="dicValueList" bordered :pagination="false">
      <span slot="flagEnable" slot-scope="text">{{ text == true ? "在用" : "停用"}}</span>
      <!-- <span slot="enabled" slot-scope="text">{{text?"在用":"停用"}}</span> -->
      <span slot="action" slot-scope="text, record">
        <a @click="toHanlder('edit',record)">编辑</a>
        <a-divider type="vertical" />
        <a @click="toHanlder('del',record)">删除</a>
      </span>
    </a-table>

  </a-modal>
</template>

<script>
  import {dicValueColumns} from "./columns.js"
  export default {
    data() {
      return {
        dicValueList:[],
        columns:dicValueColumns,
        rules:{
          parentId:[{required:true,trigger:"blur",message:"请选择所属目录"}],
          title:[{required:true,trigger:"blur",message:"请输入字典名称"}]
        },
        form:{
          dictCode:'',
          title:'',
          flagEnable: true,
          // enabled: 1,
          sortCode: 0,
          description:'',
        },
        visible:false,
        handle:'',
        dicInfo:{},

      }
    },
    methods: {
      /**
       * 显示字典值弹出框
       */
      showEditDict(row){
        this.visible=true
        this.dicInfo=row
        this.getDicValueList()
        this.$set(this.form, 'flagEnable', true)
      },
      /**
       * 显示字典值
       */
      getDicValueList(){
        this.axios.get(`/api/base/system/dictionaryValue/getDictionaryById/${this.dicInfo.code}`).then(res=>{
          this.dicValueList = res.body
        }).catch(err=>{})
      },
      /**
       * 对字典值进行操作（增、删、改）
       */
      toHanlder(handle,record){
        this.handle=handle
        this.form=record||{}
        if(handle == 'add') {
          this.form.dictCode = this.dicInfo.code
        }
        if(handle=='del'){
          this.$confirm({
            title: '警告',
            content: `真的删除该字典值吗?`,
            okType: 'danger',
            onOk: () => {
              this.axios.post(`/api/base/system/sysItems/del/${record.id}`).then(res => {
                this.toSearch()
              }).catch(err => {})
            }
          })
        }
      },
      /**
       * 提交字典值
       */
      toSubmit(){
        this.$refs.form.validate(valid=>{
          if(!valid){return}
          this.axios.post(`/api/base/system/dictionaryValue/${this.handle=='edit'?'edit':'add'}`,this.form).then(res=>{
            this.$notification.success({message: res.message})
            this.getDicValueList()
          }).catch(err=>{})
        })
      }
    },
    created() {

    }
  }
</script>

<style lang="less" scoped>
</style>
