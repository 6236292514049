<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-01-13 15:32:40
 * @LastEditors: hutian
 * @LastEditTime: 2021-01-15 14:48:53
-->
<template>
  <a-modal :maskClosable="false" title="编辑字典" style="top: 8px;" :width="800" v-model="visible" @ok="toSubmit">
    <a-form-model ref="form" :model="form" :rules="rules" layout="horizontal" :label-col="{span:4}" :wrapper-col="{span:20}">
      <a-form-model-item label="所属目录" prop="dictType">
        <a-select placeholder="请选择所属目录"  v-model="form.dictType">
          <a-select-option :value="item.id" v-for="(item, index) in parentDicList" :key="index">{{item.label}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="字典名称" prop="title">
        <a-input v-model.trim="form.title"  placeholder="字典名称" />
      </a-form-model-item>
      <a-form-model-item label="字典编号" prop="code">
        <a-input v-model.trim="form.code"  placeholder="字典编号" />
      </a-form-model-item>
      <a-form-model-item label="字典状态" prop="flagEnable">
        <a-radio-group v-model="form.flagEnable">
          <a-radio :value="true">启用</a-radio>
          <a-radio :value="false">停用</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="字典描述" prop="represent">
        <a-textarea v-model.trim="form.represent" :max-length="200" placeholder="字典描述"   />
      </a-form-model-item>
      <a-form-model-item label="字典排序" prop="sort">
        <a-input v-model.trim="form.sort"  placeholder="字典排序" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
  export default {
    data() {
      return {
        parentDicList:[],
        rules:{
          dictType:[{required:true,trigger:"blur",message:"请选择所属目录"}],
          title:[{required:true,trigger:"blur",message:"请输入字典名称"}]
        },
        form:{
          id:'',
          dictType:'',
          title:"",
          enCode:'',
          sort:0,
          flagEnable:true,
          represent:'',
        },
        visible:false,
        handle:'add'

      }
    },
    methods: {
      /**
       * 显示编辑框
       */
      showEditDict(parentDicList,handle,dictInfo){
        this.parentDicList=parentDicList
        this.visible=true
        this.handle=handle
        this.form=dictInfo?dictInfo:{}
      },
      /**
       * 提交字典
       */
      toSubmit(){
        this.$refs.form.validate(valid=>{
          if(!valid){return}
          this.axios.post(`/api/base/system/dictionary/${this.handle=='edit'?'edit':'add'}`,this.form).then(res=>{
            this.$notification.success({message: res.message})
            this.visible=false
            this.$emit('reload')
          }).catch(err=>{})
        })
      }
    },
    created() {

    }
  }
</script>

<style lang="less" scoped>
</style>
